import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3efa2d28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "yh-code" }
const _hoisted_2 = { class: "bg" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("pre", _hoisted_2, [
      _createTextVNode("      "),
      _createElementVNode("code", {
        class: _normalizeClass('language'+_ctx.language),
        innerHTML: _ctx.highlightedCode
      }, null, 10, _hoisted_3),
      _createTextVNode("\n    ")
    ])
  ]))
}