
import { defineComponent } from 'vue'

import YhDescriptions from '@/base-ui/descriptions'
import YhTextLink from '@/base-ui/text-link'
import YhCode from '@/base-ui/code'
import YhCard from '@/base-ui/card'

import {
  technologyStacks,
  dependencies,
  devDependencies,
  projectDir
} from './config'
export default defineComponent({
  components: {
    YhDescriptions,
    YhCard,
    YhTextLink,
    YhCode
  },

  setup() {
    return {
      technologyStacks,
      dependencies,
      devDependencies,
      projectDir
    }
  }
})
