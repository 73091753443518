import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5239a6c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_descriptions, {
      class: "margin-top",
      title: _ctx.title,
      column: _ctx.column,
      border: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableDatas, (itemData, index) => {
          return (_openBlock(), _createBlock(_component_el_descriptions_item, { key: index }, {
            label: _withCtx(() => [
              _createTextVNode(_toDisplayString(itemData.name), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_tag, { size: "small" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(itemData.description), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["title", "column"])
  ]))
}