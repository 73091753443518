
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    textArrs: {
      type: Array,

      default: () => []
    }
  },

  setup() {
    return {}
  }
})
