import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69d3abc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = { class: "item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "box-card" }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _: 3
  }))
}