export const projectDir = `
.
├── README.md
├── babel.config.js
├── package-lock.json
├── package.json
├── public
│   ├── favicon.svg
│   └── index.html
├── src
│   ├── App.vue
│   ├── assets
│   │   ├── css
│   │   │   ├── bass.less
│   │   │   └── index.less
│   │   └── img
│   │       ├── login-bg.svg
│   │       └── logo.svg
│   ├── base-ui
│   │   ├── breadcrumb
│   │   │   ├── index.ts
│   │   │   ├── src
│   │   │   │   └── breadcrumb.vue
│   │   │   └── types
│   │   │       └── index.ts
│   │   ├── card
│   │   │   ├── index.ts
│   │   │   └── src
│   │   │       └── card.vue
│   │   ├── code
│   │   │   ├── index.ts
│   │   │   └── src
│   │   │       └── code.vue
│   │   ├── descriptions
│   │   │   ├── index.ts
│   │   │   ├── src
│   │   │   │   └── descriptions.vue
│   │   │   └── types
│   │   │       └── types.ts
│   │   ├── form
│   │   │   ├── index.ts
│   │   │   ├── src
│   │   │   │   └── form.vue
│   │   │   └── types
│   │   │       └── index.ts
│   │   ├── table
│   │   │   ├── index.ts
│   │   │   └── src
│   │   │       └── table.vue
│   │   └── text-link
│   │       ├── index.ts
│   │       └── src
│   │           └── textlink.vue
│   ├── components
│   │   ├── nav-header
│   │   │   ├── index.ts
│   │   │   └── src
│   │   │       ├── config
│   │   │       │   └── modal.config.ts
│   │   │       ├── nav-header.vue
│   │   │       ├── page
│   │   │       │   ├── update-password.vue
│   │   │       │   └── upload-avatar.vue
│   │   │       └── user-info.vue
│   │   ├── nav-menu
│   │   │   ├── index.ts
│   │   │   └── src
│   │   │       └── nav-menu.vue
│   │   ├── page-content
│   │   │   ├── index.ts
│   │   │   └── src
│   │   │       └── page-content.vue
│   │   ├── page-modal
│   │   │   ├── index.ts
│   │   │   └── src
│   │   │       └── page-modal.vue
│   │   └── page-search
│   │       ├── index.ts
│   │       └── src
│   │           └── page-search.vue
│   ├── global
│   │   ├── index.ts
│   │   ├── register-element.ts
│   │   └── register-properties.ts
│   ├── hooks
│   │   ├── useExportData.ts
│   │   ├── usePageModal.ts
│   │   ├── usePageSearch.ts
│   │   └── useReceiptState.ts
│   ├── main.ts
│   ├── router
│   │   ├── index.ts
│   │   └── main
│   │       ├── analysis
│   │       │   ├── dashboard
│   │       │   │   └── dashboard.ts
│   │       │   └── overview
│   │       │       └── overview.ts
│   │       ├── order
│   │       │   ├── company
│   │       │   │   └── company.ts
│   │       │   └── orders
│   │       │       └── orders.ts
│   │       ├── receipt
│   │       │   ├── notrecovery
│   │       │   │   └── notrecovery.ts
│   │       │   ├── receipts
│   │       │   │   └── receipt.ts
│   │       │   └── recovery
│   │       │       └── recovery.ts
│   │       └── system
│   │           ├── menu
│   │           │   └── menu.ts
│   │           ├── role
│   │           │   └── role.ts
│   │           └── user
│   │               └── user.ts
│   ├── service
│   │   ├── index.ts
│   │   ├── login
│   │   │   ├── login.ts
│   │   │   └── type.ts
│   │   ├── main
│   │   │   └── system
│   │   │       └── system.ts
│   │   ├── request
│   │   │   ├── config.ts
│   │   │   ├── index.ts
│   │   │   └── type.ts
│   │   └── types.ts
│   ├── shims-vue.d.ts
│   ├── store
│   │   ├── index.ts
│   │   ├── login
│   │   │   ├── login.ts
│   │   │   └── type.ts
│   │   ├── main
│   │   │   └── system
│   │   │       ├── system.ts
│   │   │       └── types.ts
│   │   └── type.ts
│   ├── utils
│   │   ├── cache.ts
│   │   ├── date-format.ts
│   │   ├── map-menus.ts
│   │   └── parseTime.ts
│   ├── vendor
│   │   └── Export2Excel.js
│   └── views
│       ├── login
│       │   ├── config
│       │   │   └── account.config.ts
│       │   ├── hooks
│       │   ├── login.vue
│       │   └── pages
│       │       ├── login-account.vue
│       │       ├── login-panel.vue
│       │       └── login-phone.vue
│       ├── main
│       │   ├── analysis
│       │   │   ├── dashboard
│       │   │   │   └── dashboard.vue
│       │   │   └── overview
│       │   │       ├── config
│       │   │       │   ├── dependencies.ts
│       │   │       │   ├── dev-dependencies.ts
│       │   │       │   ├── index.ts
│       │   │       │   ├── project-dir.ts
│       │   │       │   └── technology-stacks.ts
│       │   │       └── overview.vue
│       │   ├── main.vue
│       │   ├── order
│       │   │   ├── company
│       │   │   │   ├── company.vue
│       │   │   │   └── config
│       │   │   │       ├── content.config.ts
│       │   │   │       └── modal.config.ts
│       │   │   └── orders
│       │   │       ├── config
│       │   │       │   ├── content.config.ts
│       │   │       │   ├── modal.config.ts
│       │   │       │   └── search.config.ts
│       │   │       └── orders.vue
│       │   ├── receipt
│       │   │   ├── notrecovery
│       │   │   │   ├── config
│       │   │   │   │   ├── content.config.ts
│       │   │   │   │   └── search.config.ts
│       │   │   │   └── notrecovery.vue
│       │   │   ├── receipts
│       │   │   │   ├── config
│       │   │   │   │   ├── content.config.ts
│       │   │   │   │   └── search.config.ts
│       │   │   │   └── receipt.vue
│       │   │   └── recovery
│       │   │       ├── config
│       │   │       │   ├── content.config.ts
│       │   │       │   └── search.config.ts
│       │   │       └── recovery.vue
│       │   └── system
│       │       ├── menu
│       │       │   ├── config
│       │       │   │   └── content.config.ts
│       │       │   └── menu.vue
│       │       ├── role
│       │       │   ├── config
│       │       │   │   ├── content.config.ts
│       │       │   │   ├── modal.config.ts
│       │       │   │   └── search.config.ts
│       │       │   └── role.vue
│       │       └── user
│       │           ├── config
│       │           │   ├── content.config.ts
│       │           │   ├── modal.config.ts
│       │           │   └── search.config.ts
│       │           └── user.vue
│       └── not-found
│           └── not-found.vue
├── tsconfig.json
└── vue.config.js
`
