import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79e1f68b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-link" }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textArrs, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "item"
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(item.title) + " : ", 1),
        _createVNode(_component_el_link, { type: "primary" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.desp), 1)
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ]))
}